export function modal() {
    // Click function for show the Modal
    $(".show").on("click", function () {
    });
    $(document).on("click",".show",function() {
        $(".mask").addClass("active");
    });
    // Function for close the Modal

    function closeModal() {
        $(".mask").removeClass("active");
    }

    // Call the closeModal function on the clicks/keyboard

    $(document).on("click",".close, .mask, .done",function() {
        closeModal();
    });

    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            closeModal();
        }
    });

    
}

export default modal;
