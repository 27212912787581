// import './modernizr';
import './jquery.calendario';

export function calendar() {
	$(function () {
		var selectedDates = [];
		var totalPrices = 0;
		var totalPricesVAT = 0;

		function calcPriceWithVAT() {
			var newPrice = 0;
			selectedDates.forEach(element => {
				newPrice += element.slot.price;
				if(element.products != undefined && element.products.length) {
					element.products.forEach(product => {
						newPrice += product.totalprice;
					});
				}
			});
			totalPrices = newPrice;
			var percentage = $('input[name=percentage]').val();
			totalPricesVAT = totalPrices * 1.25;
			if (percentage > 0) {
				totalPricesVAT = totalPricesVAT - ((totalPricesVAT / 100) * percentage);
			}
		}
		function uuidv4() {
			return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		}

		if ($(".custom-calendar-wrap").length > 0) {
			var transEndEventNames = {
				'WebkitTransition': 'webkitTransitionEnd',
				'MozTransition': 'transitionend',
				'OTransition': 'oTransitionEnd',
				'msTransition': 'MSTransitionEnd',
				'transition': 'transitionend'
			},
				transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
				$wrapper = $('#custom-inner'),
				$calendar = $('#calendar'),
				cal = $calendar.calendario({
					onDayClick: function ($el, $contentEl, dateProperties) {
						if ($el.hasClass("fc-today") || $el.hasClass("fc-previous") || $contentEl.find("span").hasClass("full")) {
							return;
						}
						dateProperties.day = dateProperties.day.length == 1 ? "0" + dateProperties.day : dateProperties.day
						var pageId = $("body").data("pageid");
						var date = dateProperties.day + " " + dateProperties.monthname + " " + dateProperties.year;
						var formatDate = moment(date, "DD MMMM yyyy").format('DD-MM-yyyy');

						getAvalibleslots(date)
					},
					caldata: SetDates(),
					displayWeekAbbr: true
				}),
				$month = $('#custom-month').html(cal.getMonthName()),
				$year = $('#custom-year').html(cal.getYear());

			function getAvalibleslots(date) {
				$.ajax({
					url: "/umbraco/api/Calendar/CheckAvaliableSlots",
					type: 'GET',
					dataType: 'json', // added data type
					data: {
						pageid: $("body").data("pageid"),
						date: date
					},
					success: function (slots) {
						addSlotsToModal(date, slots)
					}
				});
			}

			function addSlotsToModal(date, slots) {
				var dateWrapper = $(".date");
				

				$(".modal-content").empty();
				$(".modal-content").append("<h4 class='date'>" + date + "</h4>")
				$(".modal-content").append("<div class='modal-slots'></div>");
				var slotsWrapper = $(".modal-slots");
				$(".modal-content").append(`
                    <button class="close" role="button">
                        <?xml version="1.0" ?>
                        <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="info" /><g id="icons"><path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" id="exit" /></g></svg>
                    </button>
                `)
				$(".modal-content").append(`
					<button class="done">
						<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title></title><g data-name="Layer 2" id="Layer_2"><path d="M1,16A15,15,0,1,1,16,31,15,15,0,0,1,1,16Zm28,0A13,13,0,1,0,16,29,13,13,0,0,0,29,16Z"></path><path d="M12.13,21.59,17.71,16l-5.58-5.59a1,1,0,0,1,0-1.41h0a1,1,0,0,1,1.41,0l6.36,6.36a.91.91,0,0,1,0,1.28L13.54,23a1,1,0,0,1-1.41,0h0A1,1,0,0,1,12.13,21.59Z"></path></g></svg>
					</button>
				`);
				var daySelected = false;

				$.each(slots, function (key, value) {

					var checkIfAddedBefore = pluckByIdDate(selectedDates, value.Id, date, value.StartTime, value.EndTime, true);
					if (checkIfAddedBefore == undefined) {
						slotsWrapper.append(`
                            <div class="modal-slot">
                                <div class="time">
                                <span style="background-color: ` + value.Color + `"></span><p>` + value.StartTime + ` - ` + value.EndTime + `</p>
                                </div>
                                <div><p>` + value.Price + ` DKK</p></div>
                                <button class="showbtn addBooking" data-price="` + value.Price + `" data-id="` + value.Id + `" data-start="` + value.StartTime + `" data-end="` + value.EndTime + `" data-date="` + date + `">Book</button>
                                <button class="hidebtn removeBooking" data-price="` + value.Price + `" data-id="` + value.Id + `" data-start="` + value.StartTime + `" data-end="` + value.EndTime + `" data-date="` + date + `">Remove</button>
                            </div>
                            `)
					} else {
						daySelected = true;
						slotsWrapper.append(`
                            <div class="modal-slot">
                                <div class="time">
                                <span style="background-color: ` + value.Color + `"></span><p>` + value.StartTime + ` - ` + value.EndTime + `</p>
                                </div>
                                <div><p>` + value.Price + ` DKK</p></div>
                                <button class="hidebtn addBooking" data-price="` + value.Price + `" data-id="` + value.Id + `" data-start="` + value.StartTime + `" data-end="` + value.EndTime + `" data-date="` + date + `">Book</button>
                                <button class="showbtn removeBooking" data-price="` + value.Price + `" data-id="` + value.Id + `" data-start="` + value.StartTime + `" data-end="` + value.EndTime + `" data-date="` + date + `">Remove</button>

                            </div>
                            `)
					}
				});
				if (daySelected) {
					$(".addBooking").addClass("deactive");
				}
				$(".mask").addClass("active");
			}

			var selectedDate = 0;
			var selectedSlotId = 0;

			$(document).on("click", ".addBooking", function () {
				var hasProducts = $(".modal").data("hasproducts");
				console.log(hasProducts)
				if(hasProducts != 'False') {
					$(".modal-content").hide();
					$(".modal-products").show();
				} else {
					$(".mask").removeClass("active");
				}
				var button = $("button.done");
				if (!button.hasClass("active")) {
					button.addClass("active")
				}
				$('.addBooking').not(this).addClass("deactive");
				if ($(this).hasClass("deactive")) {
					return;
				}
				var id = $(this).data("id");
				var date = $(this).data("date");

				selectedDate = date;
				selectedSlotId = id;

				var startTime = $(this).data("start");
				var endTime = $(this).data("end");
				var price = $(this).data("price");
				var booking = {
					"id": id,
					"date": date,
					"slot": {
						"startTime": startTime,
						"endTime": endTime,
						"price": price,
						"id": id
					}
				}

				var exists = pluckByIdDate(selectedDates, id, date, startTime, endTime, true)
				if (exists == undefined) {
					selectedDates.push(booking);
					totalPrices += price;
					calcPriceWithVAT()
				}

				if (selectedDates.length > 0) {
					$(".button-next").css('display', 'flex');
					$(".booking").show();

				}

				$('*[data-id="' + id + '"]').toggleClass("showbtn").toggleClass("hidebtn");
				addPickedDates(selectedDates)
				UpdatePrice();

			});
			$(document).on("click", ".removeBooking", function () {
				var button = $("button.done");
				if (button.hasClass("active")) {
					button.removeClass("active")
				}
				$('.addBooking').removeClass("deactive");
				var id = $(this).data("id");
				var date = $(this).data("date");
				var startTime = $(this).data("start");
				var endTime = $(this).data("end");
				var price = $(this).data("price");

				var exists = pluckByIdDate(selectedDates, id, date, startTime, endTime)
				if (exists != undefined) {
					var totalProductPrice = exists.products.reduce((total, item) => total + item.totalprice, 0);
					selectedDates = selectedDates.filter(item => item !== exists);
					totalPrices -= price;
					totalPrices -= totalProductPrice;
					calcPriceWithVAT()
				}
				$('*[data-id="' + id + '"]').toggleClass("showbtn").toggleClass("hidebtn");

				if (selectedDates.length > 0) {
					$(".button-next").css('display', 'flex');
					$(".booking").show();
				} else {
					$(".button-next").hide();
					$(".booking").hide();
				}
				addPickedDates(selectedDates)
				UpdatePrice();

			});

			// Product add
			$(document).on("click", ".save-product", function () {
				var selectedProducts = [];
				var products = $(".product");
				products.each(function (index, element) {
					var multiAmount = $(element).data("productamount");
					var productPrice = $(element).data("productprice");
					var productname = $(element).data("productname");
					var amount = 1;
					var isSelected = false;
					var ischecked = $(element).find('input[type="checkbox"]').prop('checked');

					if (multiAmount) {
						amount = $(element).find(".product-amount").val()
						isSelected = amount > 0 ? true : false;
					} else if (!multiAmount && ischecked) {
						isSelected = true;
					}
					var productItem = {
						name: productname,
						price: productPrice,
						selectAmount: multiAmount,
						amount: amount,
						totalprice: productPrice * amount
					}
					if (isSelected) {
						selectedProducts.push(productItem);
					}
				});
				var currentBooking = selectedDates.find(x => x.id == selectedSlotId && x.date == selectedDate);
				currentBooking.products = selectedProducts;

				var totalProductPrice = selectedProducts.reduce((total, item) => total + item.totalprice, 0);
				totalPrices += totalProductPrice;
				UpdatePrice()
				addPickedDates(selectedDates)


				$(".modal-content").show();
				$(".modal-products").hide();
				$(".mask").removeClass("active");
				$(".button-next").hide();
				$(".calendar").hide();
				$(".calendar-form").show();
				$(".button-back").css('display', 'flex');
				$('input[type="checkbox"]').prop('checked', false);
				$('select').prop('selectedIndex', 0);
			});

			$(document).on("click", ".skip-product", function () {
				console.log("REmove")
				$('input[type="checkbox"]').prop('checked', false);
				$('select').prop('selectedIndex', 0);

				$(".modal-content").show();
				$(".modal-products").hide();
				$(".mask").removeClass("active");
				$(".button-next").hide();
				$(".calendar").hide();
				$(".calendar-form").show();
				$(".button-back").css('display', 'flex');
			});

			// Product add


			// Check if object is in array
			function pluckByIdDate(inArr, id, date, startTime, endTime, exists) {
				for (var i = 0; i < inArr.length; i++) {
					if (inArr[i].slot.startTime == startTime && inArr[i].slot.endTime == endTime && inArr[i].id == id && inArr[i].date == date) {
						return inArr[i];
					}
				}
			}

			function UpdatePrice() {
				calcPriceWithVAT()

				$('input[name=price]').val(totalPricesVAT);
				if (totalPrices > 0) {
					$(".booking").html("Pay");
				} else {
					$(".booking").html("Book");
				}


			}

			function addPickedDates(bookings) {
				var price = 0;

				var group_to_values = bookings.reduce(function (obj, item) {
					obj[item.date] = obj[item.date] || [];
					obj[item.date].push(item.slot);
					return obj;
				}, {});

				var groups = Object.keys(group_to_values).map(function (key) {
					return { id: group_to_values[key].id, date: key, slots: group_to_values[key] };
				});
				$(".receipt-bookings").empty();
				$(".receipt__total").empty();
				var array = [];
				for (let i = 0; i < groups.length; i++) {
					array.push("<p class='receipt__date'>" + groups[i].date + "</p>");
					array.push("<dl class='receipt__list'>")
					for (let k = 0; k < groups[i].slots.length; k++) {
						var productsText = "";
						var booking = bookings.find(x => x.id == groups[i].slots[k].id);
						if (booking.products != undefined && booking.products.length > 0) {
							for (let i = 0; i < booking.products.length; i++) {
								const p = booking.products[i];
								price += p.totalprice;
								productsText += `
									<div class="receipt__list-row product-item" >
										<dt class="receipt__item">`+ p.amount + ` x ` + p.name + `</dt>
										<dd class="receipt__cost">`+ p.totalprice + ` DKK <span style=" font-size: 10px; font-weight: 800; margin-left: 5px; line-height: 19px; align-self: flex-end; ">Ex. VAT</span>
										<button class="removeProducts" data-productname='`+p.name+`' data-bookeddate='`+booking.id+`'" >
													<?xml version="1.0" ?>
													<svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="info" /><g id="icons"><path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" id="exit" /></g></svg>
											</button>
									</div>
								`;

							}
						}
						array.push(`
									<div class="receipt__list-row">
											<dt class="receipt__item">` + groups[i].slots[k].startTime + ` - ` + groups[i].slots[k].endTime + `</dt>
											<dd class="receipt__cost">` + groups[i].slots[k].price + ` DKK <span style=" font-size: 10px; font-weight: 800; margin-left: 5px; line-height: 19px; align-self: flex-end; ">Ex. VAT</span>
											<button class="removeBooking" data-price="` + groups[i].slots[k].price + `" data-id="` + groups[i].slots[k].id + `" data-start="` + groups[i].slots[k].startTime + `" data-end="` + groups[i].slots[k].endTime + `" data-date="` + groups[i].date + `">
													<?xml version="1.0" ?>
													<svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="info" /><g id="icons"><path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" id="exit" /></g></svg>
											</button>
									</div>
									`+ productsText
							+ `
                        `)

						price += groups[i].slots[k].price;
					}

					array.push("</dl>")
				}

				$('input[name=baseprice]').val(price);
				$('input[name=price]').val(totalPricesVAT);
				$(".receipt__total").show();
				$(".receipt-bookings").html(array.join(''));
				$(".receipt__total").html(`
					<div class="receipt__list-row receipt__list-row--total">
						<dt class="receipt__item">Total</dt>
						<dd class="receipt__cost">` + totalPrices + ` DKK 
						<span style=" font-size: 10px; font-weight: 800; margin-left: 5px; line-height: 19px; align-self: flex-end; ">ex. VAT</span>
						</dd>
					</div>
					<div class="receipt__list-row receipt__list-row--total-vat">
						<dt class="receipt__item">Total VAT incl.</dt>
						<dd class="receipt__cost">` + totalPricesVAT + ` DKK 
						<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">incl. VAT</span>
						</dd>
					</div>
											
				`);
			}

			$(document).on("click", ".removeProducts", function () {
					var productName = $(this).data("productname");
					var bookingId = $(this).data("bookeddate");
					var booking = selectedDates.find(x => x.id == bookingId);

					booking.products = booking.products.filter(obj => obj.name !== productName);
					calcPriceWithVAT()
					
					addPickedDates(selectedDates)
					console.log(booking)
			});

			$('#custom-next').on('click', function () {
				cal.gotoNextMonth(updateMonthYear);
			});
			$('#custom-prev').on('click', function () {
				cal.gotoPreviousMonth(updateMonthYear);
			});
			$('#custom-current').on('click', function () {
				cal.gotoNow(updateMonthYear);
			});
			function updateMonthYear() {
				$month.html(cal.getMonthName());
				$year.html(cal.getYear());
			}
			$(document).on("click", ".custom-content-close", function () {
				hideEvents();
			});


			function objectifyForm(formArray) { // serialize data function
				var returnArray = {};
				for (var i = 0; i < formArray.length; i++) {
					returnArray[formArray[i]['name']] = formArray[i]['value'];
				}
				return returnArray;
			}
			// button next step

			$(document).on("click", ".button-next", function (e) {
				$(".button-back").css('display', 'flex');
				$(".button-next").hide();
				$(".calendar").hide();
				$(".calendar-form").show();
				$(".step2").addClass("active");
				$(".indicator-line").remove("active");
				$(".indicator-line").addClass("active");

			});
			$(document).on("click", ".button-back", function (e) {
				$(".button-back").hide();
				$(".button-next").css('display', 'flex');
				$(".calendar").show();
				$(".calendar-form").hide();
				$(".step2").removeClass("active");
				$(".indicator-line").remove("active");

			});

			// Sent to user to payment
			$(document).on("click", ".booking", function (e) {
				e.preventDefault();
				var isvalid = validateForm();
				if (isvalid) {
					$(".calendar-loader").toggleClass("hide");
					var data = objectifyForm($('.calendarform').serializeArray());
					data["uniqid"] = uuidv4();
					data["selecteddates"] = selectedDates;
					var bookinginfo = JSON.stringify(data);
					Cookies.set('booking', bookinginfo);
					var pageid = $("body").data("pageid");
					makePayment(data.price, data.discountCode, pageid)
				}
			});
			$(document).on("click", ".invoice", function (e) {
				e.preventDefault();
				var isvalid = validateForm();
				if (isvalid) {
					$(".calendar-loader").toggleClass("hide");
					var data = objectifyForm($('.calendarform').serializeArray());
					data["uniqid"] = uuidv4();
					data["selecteddates"] = selectedDates;
					data["isPaid"] = false;
					var bookinginfo = JSON.stringify(data);
					Cookies.set('booking', bookinginfo);
					console.log(data)
					SendInvoice(bookinginfo)
				}
			});
			function SendInvoice(bookinginfo) {
				$.ajax({
					url: "/umbraco/api/Calendar/ForInvoice",
					contentType: "application/json; charset=UTF-8",
					type: 'POST',
					dataType: 'json',
					data: bookinginfo,
					error: function (data) {
						console.log("Error: ", data)
					},
					success: function (response) {
						window.location.replace(response)
					}
				});
			}




			function validateForm() {
				var required = $('input,textarea,select').filter(function () { return this.value == "" && this.hasAttribute('required') });
				$("input").removeClass("notvalid")
				var allRequired = true;
				required.each(function () {
					if ($(this).val() == '') {
						allRequired = false;
					} else {
						$(this).removeClass("notvalid")
					}
				});
				if (!allRequired) {
					required.addClass("notvalid")
					return false;
				} else {
					return true;
				}
			}

			//Pay for the booking
			function makePayment(price, coupon, pageid) {
				if (price == 0) {
					$.ajax({
						url: '/umbraco/surface/payment/GetSuccessPage?pageId=' + pageid,
						type: 'GET',
						success: function (response) {
							window.location.replace(response)
						},
						error: function (data) {
							console.log("Error: ", data)
						},
					});
				} else {
					fetch('/umbraco/surface/payment/MakeAPayment?pageId=' + pageid + '&coupon=' + coupon + '&price=' + price, { method: 'POST' })
						.then(function (response) {
							if (response.url != undefined && response.redirected) {
								window.location.href = response.url;
							} else if (response != null) {
								return response.json();
							}
						})
						.then(function (session) {
							if (session != null) {
								return stripe.redirectToCheckout({ sessionId: session.id });
							}
						}).then(function (result) {
							// If `redirectToCheckout` fails due to a browser or network
							// error, you should display the localized error message to your
							// customer using `error.message`.
							if (result.error) {
								alert(result.error.message);
							}
						}).catch(function (error) {
							console.error('Error:', error);
						});
				}

			}


			var pkkey = $(".custom-calendar-wrap").data("publishkey");
			var stripe = Stripe(pkkey);
			var checkoutButton = document.getElementById('checkout-button');


			function hideEvents() {

				var $events = $('#custom-content-reveal');
				if ($events.length > 0) {

					$events.css('top', '100%');
					Modernizr.csstransitions ? $events.on(transEndEventName, function () {
						$(this).remove();
					}) : $events.remove();

				}

			}

			function SetDates() {
				$.ajax({
					url: "/umbraco/api/calendar/GetCal",
					type: 'GET',
					dataType: 'json', // added data type
					data: {
						pageid: $("body").data("pageid")
					},
					success: function (res) {
						var calData = {};
						if (res.AvailableDatesOnBookedDates != null) {
							$.each(res.AvailableDatesOnBookedDates, function (key, v) {
								var slot = "";
								if (v.Slots.length < res.CalendarSlots.length) {

									$.each(v.Slots, function (key, value) {
										slot += '<span class="book-mark" style="background-color: ' + value.Color + '"></span>'
									});
								} else {
									slot += '<span class="full"></span>';
									$.each(v.Slots, function (key, value) {
										slot += '<span class="book-mark" style="background-color: ' + value.Color + '"></span>'
									});
								}
								calData[v.Date] = slot
							});
						}
						cal.setData(calData);
						$(".calendar-loader").toggleClass("hide");

					}
				});
			}

			$(document).on("click", ".removeCoupon", function (e) {
				e.preventDefault();
				var pageId = $("body").data("pageid");

				var newVatPrice = totalPrices * 1.25;
				$('input[name=price]').val(newVatPrice);
				$(".receipt__list-row--total-discount").remove();
				$(".receipt__list-row--total-vat .receipt__cost").html(`
					` + totalPrices + ` DKK
					<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">incl. VAT</span>
				`)
				if (totalPrices > 0) {
					$(".booking").html("Pay");
				} else {
					$(".booking").html("Book");
				}
				$(".removeCoupon").hide();
				$("#couponcode").val("");
			});
			$(document).on("click", ".addCode", function (e) {
				e.preventDefault();
				var pageId = $("body").data("pageid");
				var code = $("#couponcode").val();
				var price = totalPrices;
				var newVatPrice = price * 1.25;
				$.ajax({
					url: "/umbraco/surface/payment/GetCoupon?pageId=" + pageId + "&code=" + code,
					contentType: "application/json; charset=UTF-8",
					type: 'Get',
					dataType: 'json',
					// data: JSON.stringify(data),
					error: function (data) {
						console.log("Error: ", data)
					},
					success: function (response) {
						var discount_pct = response;
						if (discount_pct > 0) {
							var newPrice = price - (price * discount_pct / 100);
							var newVatPrice = newPrice * 1.25;
							$(".receipt__list-row--total-discount").remove();
							$(".receipt__list-row--total-vat .receipt__cost").html(`
								` + newVatPrice + ` DKK
								<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">incl. VAT</span>
							`)
							$('input[name=price]').val(newVatPrice);

							if (newPrice > 0) {
								$(".booking").html("Pay");
							} else {
								$(".booking").html("Book");
							}

							$(".removeCoupon").show();
							$("#couponcode").removeClass("notvalid");
							$(`
							<div class="receipt__list-row receipt__list-row--total-discount">
								<dt class="receipt__item">Discount (`+ discount_pct + `%)</dt>
								<dd class="receipt__cost">`+ newPrice + `
								<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">ex. VAT</span>
								</dd>
							</div>
							` ).insertAfter(".receipt__list-row--total");
						} else {
							$(".receipt__list-row--total-discount").remove();
							$("#couponcode").addClass("notvalid");
							$('input[name=price]').val(newVatPrice);
							$(".removeCoupon").hide();
						}
					}
				});


			});
		}
		// KEYPAD
		if ($(".custom-calendar-wrap").length > 0) { // Member login
			$(document).on("click", ".member-login", function (e) {
				e.preventDefault();
				$(".mask").addClass("active");
				$(".modal").addClass("pin");
				var modal = $(".modal");
				modal.empty();
				modal.append(`
							<div id="PINcode"></div>
					`)
				modal.append(`
									<button class="close" role="button">
											<?xml version="1.0" ?>
											<svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="info" /><g id="icons"><path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" id="exit" /></g></svg>
									</button>
							`)
				$("#PINcode").html("<form action='' method='' name='PINform' id='PINform' autocomplete='off' draggable='true'>" + "<input id='PINbox' type='password' value='' name='PINbox' disabled />" + "<br/>" + "<input type='button' class='PINbutton num' name='1' value='1' id='1'/>" + "<input type='button' class='PINbutton num' name='2' value='2' id='2'/>" + "<input type='button' class='PINbutton num' name='3' value='3' id='3'/>" + "<br>" + "<input type='button' class='PINbutton num' name='4' value='4' id='4'/>" + "<input type='button' class='PINbutton num' name='5' value='5' id='5'/>" + "<input type='button' class='PINbutton num' name='6' value='6' id='6'/>" + "<br>" + "<input type='button' class='PINbutton num' name='7' value='7' id='7'/>" + "<input type='button' class='PINbutton num' name='8' value='8' id='8'/>" + "<input type='button' class='PINbutton num' name='9' value='9' id='9'/>" + "<br>" + "<input type='button' class='PINbutton clear' name='-' value='clear' id='-' />" + "<input type='button' class='PINbutton num' name='0' value='0' id='0'/>" + "<input type='button' class='PINbutton enter' name='+' value='enter' id='+' />" + "</form>");

			});

			$(document).on("click", ".num", function (e) {
				e.preventDefault();
				var num = $(this);
				var v = $("#PINbox").val();
				$("#PINbox").val(v + num.val());
			});

			$(document).on("click", ".enter", function (e) {
				var pass = $("#PINbox").val();
				if (pass == "") {
					alert("Enter a PIN");
				} else {
					Getuser(pass);
				};
			});
			$(document).on("click", ".clear", function (e) {
				$("#PINbox").val("");
			});

			function Getuser(password) {
				$.ajax({
					url: "/umbraco/api/member/GetMember",
					type: 'GET',
					dataType: 'json', // added data type
					data: {
						password: password
					},
					success: function (user) {
						if (user == null) {
							$("#PINbox").addClass("error")
						} else {
							$("#PINbox").removeClass("error")
							$(".mask").removeClass("active");
							$(".modal").removeClass("pin");
							fillform(user)
						}
						$("#PINbox").val("");
					}
				});
			}
			function fillform(user) {
				var totalProductPrice = 0;
				for (let i = 0; i < selectedDates.length; i++) {
					var products = selectedDates[i].products;
					if (products != undefined) {
						for (let k = 0; k < selectedDates[i].products.length; k++) {
							totalProductPrice += selectedDates[i].products[k].totalprice;
						}
					}
				}
				console.log(totalProductPrice)
				$(".receipt__list-row--total-discount").remove();
				$(".invoice").show();
				var currentpriceVat = $('input[name=baseprice]').val() * 1.25;
				var currentpriceExVat = $('input[name=baseprice]').val();

				var newpriceEXVAT = currentpriceExVat - (currentpriceExVat / 100 * user.Discount)
				var newpriceVAT = (currentpriceVat - (currentpriceVat / 100 * user.Discount))

				$('input[name=name]').val(user.Name)
				$('input[name=phone]').val(user.Phone)
				$('input[name=email]').val(user.Email)
				$('input[name=company]').val(user.Company)
				$('input[name=cvr]').val(user.Cvr)
				$('input[name=address]').val(user.Address)
				$('input[name=city]').val(user.City)
				$('input[name=zip]').val(user.Zip)
				$('input[name=invoiceemail]').val(user.InvoiceEmail)
				$('input[name=isCalendarUser]').val(true)
				$('input[name=price]').val(newpriceVAT)
				$('input[name=percentage]').val(user.Discount)

				$(`
					<div class="receipt__list-row receipt__list-row--total-discount">
							<dt class="receipt__item">Discount (`+ user.Discount + `%)</dt>
							<dd class="receipt__cost">`+ newpriceEXVAT + ` DKK
							<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">ex. VAT</span>
							</dd>
					</div>
					` ).insertAfter(".receipt__list-row--total");

				$(".receipt__list-row--total-vat .receipt__cost").html(`
				` + newpriceVAT + ` DKK
				<span style=" font-size: 10px; font-weight: 400; margin-left: 5px; line-height: 19px; align-self: flex-end; ">incl. VAT</span>
			`)

				if (newpriceVAT > 0) {
					$(".booking").html("Pay");
				} else {
					$(".booking").html("Book");
				}
			}
		}

		//KEYPAD
		// Add the booking to after success page 
		function addBooking(data) {
			if (Cookies.get('booking') != undefined) {
				$.ajax({
					url: "/umbraco/api/calendar/AddBooking",
					contentType: "application/json; charset=UTF-8",
					type: 'Post',
					dataType: 'json',
					data: JSON.stringify(data),
					error: function (data) {
						console.log("Error: ", data)
					},
					success: function (response) {
						Cookies.remove('booking');
					}
				});
			} else {
				window.location.replace("/")
			}

		}
		if ($(".success-page").length > 0) {
			if (Cookies.get('booking') != undefined) {
				var data = JSON.parse(Cookies.get('booking'));
				addBooking(data)
			} else {
				window.location.replace("/")
			}

		}
		if ($(".error-page").length > 0) {
			if (Cookies.get('booking') != undefined) {
				var data = JSON.parse(Cookies.get('booking'));
				var pageId = $("body").data("pageid");
				deleteEvent(data.uniqid, pageId)
			} else {
				window.location.replace("/")
			}
			function deleteEvent(uniqid, pageid) { // Cookies.set('booking', userinfo);
				$.ajax({
					url: "/umbraco/api/calendar/DeleteBookingById?pageId=" + pageid + "&id=" + uniqid,
					type: 'GET',
					dataType: 'json', // added data type
					success: function (res) {
						if (res) {
							Cookies.remove('booking')
						}
					},
					error: function (error) {
						console.log("E: ", error)
					}
				});
			}

		}
	});
}

export default calendar;
