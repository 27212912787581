
export function internalbooking() {
    
    if (!$(".internal-wrapper").length) {
        return;
    }
    var interval;
    var timer = function(){
        clearInterval(interval);
        interval = setInterval(function(){
            reloadpage();
        }, 30000);
    };

    var starttime = "";
    var countdowntime = "";
    var currentBookingTime = new Date();
    var now = new Date();
    var number = 15;
    
    var isDelete = false;

    optimizeTime()
    setTimer();
    getBookingTime();
    
    $(function () {
        getInternalCalendarData(starttime);

    });

    function optimizeTime() {
        if (now.getMinutes() < 30) {
            now.setMinutes(30);
        } else {
            now.setHours(now.getHours() + 1, 0);
        }
    }
    function setClockDate() {
        var currenttimeAndDate = new Date();
        var date = moment(currenttimeAndDate).format("MMM Do YYYY");
        var day = moment(currenttimeAndDate).format("dddd");
        var time = moment(currenttimeAndDate).format("HH:mm");
        $(".time-wrapper .time").html("<p>" + time + "</p>")
        $(".time-wrapper .date").html("<p>" + day + "</p><p>" + date + "</p>")
    }
    setClockDate();
    setInterval(function () { 
        setClockDate() 
        getInternalCalendarData(starttime);
    }, 
        30000);



    function setTimer() {
        var hour = now.getHours().toString().length == 1 ? "0" + now.getHours() : now.getHours();
        $(".hour").html(hour)
        var minut = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
        $(".minut").html(minut)
    }


    function getBookingTime() {
        if (currentBookingTime.getMinutes() < 30) {
            currentBookingTime.setMinutes(0);
        } else {
            currentBookingTime.setHours(currentBookingTime.getHours() + 0, 0);
        }

        var miuntes = currentBookingTime.getMinutes().toString().length == 1 ? "0" + currentBookingTime.getMinutes() : currentBookingTime.getMinutes();
        var hours = currentBookingTime.getHours().toString().length == 1 ? "0" + currentBookingTime.getHours() : currentBookingTime.getHours();
        starttime = hours + ":" + miuntes;
    }

   
    $(document).on("click", ".avaliable-hour-slots span", function () {
        timer()
        if(!$(this).hasClass("notActive")) {
            $(".avaliable-hour-slots span").removeClass("active");
            
            $(this).addClass("active");
            $(".intern-book").show();
    
            $(".booking-bottom button").addClass("active");
        }
    });

    $(document).on("click", ".internal-booking", function () {
        timer()
        changeTitle("Add booking for today", false);
        $(".internal-booking").hide();
        $(".internal-sidebar").removeClass("color-green");
        $(".internal-sidebar").addClass("color-blue");


        CheckFreeSlots();
        $(".new-booking-form").css("display", "flex")
            .hide()
            .fadeIn();
    });


    

    $('#previous').click(function(){
        //go back to previous slide and reset time
       clearInterval(interval);
       
    });
    $(document).on("click", ".intern-book", function () {
        if(!$(this).hasClass("notActive")) {
            var length = $(".avaliable-hour-slots .active").text();
            var startTime = $(".hour").text() + ":" + $(".minut").text();
            var text = "Confirm <b>" + length + "</b> from <b>" + startTime + "</b> today";
            changeTitle(text, false);
            $(".new-booking-form").hide();
            $(".internal-login").css("display", "flex").hide().fadeIn();
            isDelete = false;
            timer()
        }
        

    });

    function changeTitle(text, reset) {
        if (reset) {
            $(".header-title h1").html($(".header-title h1").data("title"))
            $(".header-title span").removeClass("active");
        } else {
            $(".header-title span").addClass("active");
            $(".header-title h1").html(text)
        }
    }
    function countdown(date) {
        var endTime = new Date(date);
        endTime = (Date.parse(endTime) / 1000);

        var countdownNow = new Date();
        countdownNow = (Date.parse(countdownNow) / 1000);

        var timeLeft = endTime - countdownNow;

        var days = Math.floor(timeLeft / 86400);
        var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }

        if(hours == 0) {
            $(".countdown-hour").html("");

        } else {
            $(".countdown-hour").html(hours + " hour");
        }

        if(minutes == 0) {
            $(".countdown-minut").html("Soon");

        } else {
            
            $(".countdown-minut").html(parseInt(minutes)+1 + " min");

        }
        if(seconds <= 0) {
            $("next-meeting").hide();
        }
    }

    function getInternalCalendarData(time) {
        var pageid = $("body").data("pageid");
        $.ajax({
            url: "/umbraco/api/InternalCalendar/GetInternalCalendar",
            contentType: "application/json; charset=UTF-8",
            type: 'Get',
            dataType: 'json',
            data: {
                PageId: pageid,
                StartTime: time
            },
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true,
            error: function (data) {
                console.log("Error: ", data)
            },
            success: function (response) {
                if (response.Isbusy) {
                    countdowntime = response.EndTime;
                    setBusy()
                    countdown(moment(new Date).format("dd MMMM yyyy") + " " + countdowntime); 
                    setInterval(function () { countdown(moment(new Date).format("dd MMMM yyyy") + " " + countdowntime); }, 60000);
                    $(".next-meeting").delay(1000).fadeIn();
                    setTimeChanger(response.EndTime)
                } else {
                    setNotBusy()
                    $(".next-meeting").hide();
                }
                showBookings(response.CurrentBookings)
                $(".calendar-loader").fadeOut(300);
            },
            complete: function () {
                //call the 'doSomeRequest' when current one is complete
                // setTimeout(getInternalCalendarData("13:00   "), 9000);
            }
        });
    }

    // setInterval(function () { getInternalCalendarData("21:00") }, 1000);
    function showBookings(bookings) {
        $(".sidebar-bookings").empty();
        var sidebar = "";
        var isFirst = true;
        if(bookings.length > 0) {
            jQuery.each(bookings, function (index, booking) {
                var date = "Today";
                var now = moment(moment().format("DD MMMM YYYY"), "DD MMMM YYYY");
                var nextBooking = moment(booking[0].BookedDate, "DD MMMM YYYY")
                var dif = now.diff(nextBooking,"days")
                switch (dif) {
                    case 0:
                        date = "Today"
                        break;
                    case -1:
                        date = "Tomorrow"
                        break;
                    default:
                        date = moment(booking[0].BookedDate, "DD MMMM YYYY").format("MMM Do")
                        break;
                }
                sidebar += "<h3>" + date + "</h3>"
                sidebar += "<ul>";
                jQuery.each(booking, function (index, item) {
                    var isInternal = item.IsCalendarUser ? '<div class="deletebookings" data-id="'+ item.BookingID+'"><?xml version="1.0" encoding="iso-8859-1"?> <!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --> <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 27.965 27.965" style="enable-background:new 0 0 27.965 27.965;" xml:space="preserve"> <g> <g id="c142_x"> <path d="M13.98,0C6.259,0,0,6.261,0,13.983c0,7.721,6.259,13.982,13.98,13.982c7.725,0,13.985-6.262,13.985-13.982 C27.965,6.261,21.705,0,13.98,0z M19.992,17.769l-2.227,2.224c0,0-3.523-3.78-3.786-3.78c-0.259,0-3.783,3.78-3.783,3.78 l-2.228-2.224c0,0,3.784-3.472,3.784-3.781c0-0.314-3.784-3.787-3.784-3.787l2.228-2.229c0,0,3.553,3.782,3.783,3.782 c0.232,0,3.786-3.782,3.786-3.782l2.227,2.229c0,0-3.785,3.523-3.785,3.787C16.207,14.239,19.992,17.769,19.992,17.769z"/> </g> <g id="Capa_1_104_"> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg></div>' : '';
                    
                    sidebar += "<li>" + item.StartTime + " - " + item.EndTime + " : " + item.Company + " "+ isInternal+ "</li>";
                });
                sidebar += "</ul>";
                if(isFirst) {
                    sidebar += "<div class='divider'></div>"
                }
                isFirst = false;
            });
        } else {
            sidebar = '<h4>No meetings planned</h4>';
        }
        $(".sidebar-bookings").append(sidebar);
    }

    function setBusy() {
        $(".internal-sidebar").removeClass("color-green");
        $(".internal-sidebar").addClass("color-red");

        $(".internal-booking").removeClass("color-green");
        $(".internal-booking").addClass("color-red");
        $(".isfree").hide();

        $(".isbusy").show();
    }
    function setNotBusy() {
        $(".internal-sidebar").removeClass("color-red");
        $(".internal-sidebar").addClass("color-green");

        $(".internal-booking").removeClass("color-red");
        $(".internal-booking").addClass("color-green");
        $(".isbusy").hide();
        $(".isfree").show();
    }
    function CheckFreeSlots() {
        var starttime = $(".hour").text() + ":" + $(".minut").text();
        var pageid = $("body").data("pageid");
        $.ajax({
            url: "/umbraco/api/InternalCalendar/GetInternalCalendar",
            contentType: "application/json; charset=UTF-8",
            type: 'Get',
            dataType: 'json',
            data: {
                PageId: pageid,
                StartTime: starttime
            },
            error: function (data) {
                console.log("Error: ", data)
            },
            success: function (response) {
                var slotsData = response.MinutSlots;
                var slots = $(".avaliable-hour-slots span");
                
                slots.each(function() {
                    var isFree = slotsData.find(x => x.Length.toString() == $(this).data("length")).Free;
                    if(!isFree) {
                        $(this).addClass("notActive");
                        if($(this).hasClass("active")) {
                            $(".intern-book").addClass("notActive");
                        }
                    } else {
                        $(this).removeClass("notActive");
                        if($(this).hasClass("active")) {
                            $(".intern-book").removeClass("notActive");
                        }
                    }
                });
                
            }
        });
    }


    //Set time selector

    function setTimeChanger(endTime) {
        var hour = endTime.substr(0, 2);
        var minutes = endTime.substr(endTime.length-2, 2)
        now.setMinutes(minutes);
        now.setHours(hour);
        $(".hour").html(endTime.substr(0, 2))
        $(".minut").html(minutes)
    }
    function changeHour(increase) {
        var minutes = now.getMinutes();
        if (increase) {
            var hour = now.getHours() + 1;
            now.setMinutes(minutes);
            now.setHours(hour);
        } else {
            var hour = now.getHours() - 1;
            now.setMinutes(minutes);
            now.setHours(hour);
        }

        var hour = now.getHours().toString().length == 1 ? "0" + now.getHours() : now.getHours();
        $(".hour").html(hour)

    }

    function changeMinut(increase) {

        var hour = now.getHours();
        if (increase) {
            var minutes = now.getMinutes() + number;
            now.setMinutes(minutes);
            now.setHours(hour);
        } else {
            var minutes = now.getMinutes() - number;
            now.setMinutes(minutes);
            now.setHours(hour);
        }
        var minut = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
        $(".minut").html(minut)

    }
    $(function () {

        $(document).on("click", ".changehours", function () {
            timer();
            var increase = $(this).data("type")
            changeHour(increase)
            CheckFreeSlots()
        });

        $(document).on("click", ".changeminutes", function () {
            timer();
            var increase = $(this).data("type");
            changeMinut(increase)
            CheckFreeSlots()
        });

    });

    $(document).on("click", ".header-title span.active", function () {
        location.reload();
    });



    // Delete internal booking  
    $(document).on("click", ".deletebookings", function () {
        timer();
        changeTitle("Back to main screen", false)
        $(this).addClass("deleteThis")
        $(".internal-booking").hide();
        $(".new-booking-form").hide();
        $(".internal-sidebar").removeClass("color-green");
        $(".internal-sidebar").addClass("color-blue");
        $(".internal-login").css("display", "flex").hide().fadeIn();
        isDelete = true;
    });
    
    $(document).on("click", ".delete-booking", function () {
        $(".finish-booking").removeClass("active");
        $(".delete-booking").removeClass("active");
        var pin = $(".pin");
        var code = "";
        jQuery.each(pin, function (index, k) {
            code = $(".pin").text();
        });
        var pageid = $("body").data("pageid");
        var id = $(".deletebookings.deleteThis").data("id");

        var data = {
            "PageId": pageid,
            "Pin": code,
            "Id": id
        }
        $.ajax({
            url: "/umbraco/api/InternalCalendar/DeleteInternalBooking",
            contentType: "application/json; charset=UTF-8",
            type: 'Get',
            dataType: 'json',
            data: data,
            error: function (data) {
                console.log("Error: ", data)
            },
            success: function (response) {
                if (!response.Success) {
                    $(".errorTitle").html(response.Title);
                    $(".errorMessage").html(response.Message);
                    $(".password").addClass("error")
                    $(".delete-booking").addClass("active");
                    $(".success-msg").hide()
                } else {
                    $(".successTitle").html(response.Title);
                    $(".successMessage").html(response.Message);
                    $(".error-msg").hide();
                    $(".success-msg").css("display", "flex").hide().fadeIn();
                    setTimeout(reloadpage, 3000)

                }
            }
        });
    });



    // Keypad and ADD booking

    var count = 0;
    $(document).on("click", ".keypad span", function () {
        timer()
        if (count < 4) {
            var key = $(this).text();
            $(".pin").eq(count).html(key.trim())
            count++;
            // count == 4 ? count = 0 : count;
            if (count == 4 && !isDelete) {
                $(".finish-booking").addClass("active");
            } else if(count == 4 && isDelete) {
                $(".delete-booking").addClass("active");
            }
            else {
                $(".delete-booking").removeClass("active");
                $(".finish-booking").removeClass("active");
            }
        }

    });
    $(document).on("click", ".keypad .reset", function () {
        timer()
        $(".pin").html("");
        $(".delete-booking").removeClass("active");
        $(".finish-booking").removeClass("active");
        $(".password").removeClass("error")
        count = 0;
    });


    $(document).on("click", ".finish-booking", function () {
        timer();
        $(".finish-booking").removeClass("active");
        var pin = $(".pin");
        var code = "";
        jQuery.each(pin, function (index, k) {
            code = $(".pin").text();
        });
        var pageid = $("body").data("pageid");
        var length = $(".avaliable-hour-slots .active").data("length");
        var startTime = $(".hour").text() + ":" + $(".minut").text();

        var data = {
            "PageId": pageid,
            "ClientPin": code,
            "StartTime": startTime,
            "Length": length
        }
        $.ajax({
            url: "/umbraco/api/InternalCalendar/AddBooking",
            contentType: "application/json; charset=UTF-8",
            type: 'Post',
            dataType: 'json',
            data: JSON.stringify(data),
            error: function (data) {
                console.log("Error: ", data)
            },
            success: function (response) {
                if (!response.Success) {
                    $(".errorTitle").html(response.Title);
                    $(".errorMessage").html(response.Message);
                    $(".error-msg").css("display", "flex").hide().fadeIn();
                    // setTimeout(function(){ $(".error-msg").fadeOut() }, 3000);
                    $(".password").addClass("error");
                    $(".success-msg").hide()
                    $(".finish-booking").addClass("active");
                    setTimeout(reloadpage, 5000)

                } else {
                    $(".successTitle").html(response.Title);
                    $(".successMessage").html(response.Message);
                    $(".error-msg").hide();
                    $(".success-msg").css("display", "flex").hide().fadeIn();
                    setTimeout(reloadpage, 3000)

                }
            }
        });
    });
    function reloadpage() {
        location.reload();
    }
}

export default internalbooking;