/*
 * @title App
 * @description Application entry point
 */

// Modules
import calendar from './components/calendar';
import modal from './components/modal';
import keypad from './components/keypad';
import timer from './components/timer';
import internalbooking from './components/internal-booking';
import internalkeypad from './components/internal-keypad.js';

document.addEventListener('DOMContentLoaded', function() {
  timer();
  internalkeypad();
  internalbooking();
  calendar();
  modal();
  keypad();
})

